<template>
  <section class="section" style="padding-top: 1.5rem;">
    <div class="container Nunito">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <h1 class="title is-2 noselect">Redeem Code!</h1>
          <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Receive a code for something? Cool, enter the code below!</h1>
          <h1 style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">It can take up to 1hr to update!</h1>
        </div>
      </section>
      <section class="section" style="padding: 0;">
        <b-field class="hovermessagefeild" position="is-centered" label="Enter Redeem Code">
          <b-input :disabled="disabled" :loading="loading" id="code" @keyup.enter.native="redeemCode" expanded maxlength="23" name="code" custom-class="input-default" placeholder="A68AA-BB79B-C72CC-DD69D" type="search"></b-input>
          <p class="control">
            <b-button :disabled="disabled" :loading="loading" id="code-button" @click="redeemCode" class="button is-primary">Search</b-button>
          </p>
        </b-field>
      </section>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Promo',
    data () {
      return {
        disabled: false,
        loading: false
      }
    },
    created () {
    },
    methods: {
      redeemCode () {
        console.log('f')
        // [A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}
      }
    }
  }
</script>

<style scoped>
</style>
